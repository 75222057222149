import React, { useContext, useMemo, useRef, useEffect } from 'react'
import { ModalContext } from '../../../../../ModalContext'
import { StripeAccContext } from '../../../../contexts/StripeAccContext'
import {currencyMask} from '../../../../currency'
import { submitPayment } from '../../../../api'
import AuthStatus from './AuthStatus'
import useLoader from '../../../../hooks/useLoader'

const handleErrors = (response) => {
  if (response.success) {
    return response
  }
  const stripeError = response.stripeError
  const declineCode = response.declineCode

  if (stripeError) {
    return {success: false, error: stripeError}
  }

  if (declineCode) {
    return {success: false, error: declineCode}
  }
  return {success: false, error: 'An unexpected error has occured.'}
}

const Confirmation = ({onSuccessReturnToken, amount}) => {
  const { paymentMethodId, setPaymentSuccess, setCards, setAllPayments, type, amountWithCommission, ACH_FEE, CARD_RATE} = useContext(StripeAccContext)
  const { setCurrentModal, addToModalQueue } = useContext(ModalContext)
  const { loading, finishedLoading, withLoader } = useLoader()
  const ref = useRef()
  const commission = type === "card" ? Math.floor(amount * CARD_RATE) : ACH_FEE

  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)
    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    // modal.modal("show")
    modal.modal({ backdrop: "static", keyboard: false })

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  const confirmAndSubmitPayment = async () => {

    const data = {
      payment_method_id: paymentMethodId === 'new-card' ? '' : paymentMethodId,
      stripeToken: typeof onSuccessReturnToken === 'function' ? onSuccessReturnToken() : '',
      amount: amount || null,
      commission: commission || null
    }

    const res = await submitPayment(data)
    const {success, error} = handleErrors(res)
    if (success) {
      setPaymentSuccess(true)
      setAllPayments(res.all_payments)
      setCards(res.cards)
    } else {
      console.error('Error:', error)
    }
    const {stripeToken: token, ...submittedPaymentInfo} = data
    addToModalQueue(<AuthStatus res={res} submittedPaymentInfo={submittedPaymentInfo} retryPayment={() => submitPayment(data)} />)
    cleanup()
  }

  const confirmWithLoader = () => {
    withLoader(confirmAndSubmitPayment)
  }

  return (
    <div className='lease-fees-modal stripe-payment-process'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box payment'>
          <div className='acknowledgment-modal'>
            <div className='header'>
              <i className='fa-light fa-circle-info'></i>
              <h3>Acknowledgment</h3>
            </div>
            <div className='body'>
              <p>
                By authorizing, you agree to maintain a balance in your account that
                is equal to or greater than <span>{currencyMask((amount + commission) || amountWithCommission)}</span> until
                payment is collected.
              </p>
              <p>You will be emailed as soon as your payment has been collected.</p>
              <p className='contact'>
                If you have any questions, please contact{" "}
                <span>info@boardpackager.com</span>.
              </p>
            </div>
            <div className='button-container'>
              <button type='button' className='cancel' onClick={cleanup}>
                Cancel
              </button>
              <button
                type='button'
                className='confirm green'
                disabled={loading || finishedLoading}
                onClick={confirmWithLoader}
              >
                {loading ? <i
                  className='fa-duotone fa-loader fa-spin-pulse'
                  style={{"--fa-primary-color": '#f7f7f7', "--fa-secondary-color": '#c9c9c9'}}
                ></i> : 'Agree + Authorize'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Confirmation
