import React, { useRef, useEffect, useContext } from "react"
import { ModalContext } from "../../../../ModalContext"

const ServerError = () => {
  const { setCurrentModal } = useContext(ModalContext)
  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const modal = $(ref.current)
    const close = () => setCurrentModal(null)
    modal.on("hidden.bs.modal", close)
    modal.modal({ backdrop: "static", keyboard: false })

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box server-error'>
          <div className='modal-icon'>
            <i className='exclamation-circle-orange'></i>
          </div>
          <h3>Oops!</h3>
          <div className='body'>
            <p>There was a problem completing your request.</p>
            <p><span className="normal">Please refresh your page and try again</span></p>
            <p>
              If this issue persists, please contact us through <span className="bp-green support">Support</span> or email
              us at <a className="bp-email" href="mailto:info@boardpackager.com">info@boardpackager.com</a>.
            </p>
          </div>
          <div className='button-container center'>
            <button className='cancel' onClick={cleanup}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServerError
